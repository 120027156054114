/**
 * Solicitudes Pragma SA
 */

import axiosClient from '@/services/axiosClient';
import endpoints from '@/services/endpoints';
import type * as APITypes from '@/services/types';
import formatParams from '@/utils/formatParams';

import type * as Types from './types';

export { Types };

export const phoneNumberPragma = '3228847089';

export const getPresignedUrl = async ({
  folder,
  fileNames,
}: Types.TPresignedURLParams): Promise<APITypes.URL[]> => {
  const response: APITypes.PresignedUrl = await axiosClient({
    url: `${endpoints.advances.GET_PREFIRMED_URL}?&operacion=upload&carpeta=${folder}&nombreArchivos=${fileNames}`,
    method: 'GET',
  });

  return response?.urls;
};

export const getTreasuryList = async (
  form: APITypes.TListForm<Types.TTreasuryListForm>,
): Promise<APITypes.TResponseList<Types.TAdvanceTreasuryItem>> => {
  const response: APITypes.TResponseList<Types.TAdvanceTreasuryItem> = await axiosClient({
    url: endpoints.advances.GET_TREASURY_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const setPaid = async (form: { id: number }[]): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: endpoints.advances.PUT_SET_PAID,
    method: 'PUT',
    data: form,
  });

  return response.mensaje;
};

export const legalize = async (form: Types.TLegalizeForm): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_LEGALIZE}/${form.id}`,
    method: 'PUT',
    data: form,
  });

  return response.mensaje;
};

export const getDetails = async (id: number): Promise<Types.TAdvanceDetails | null> => {
  const response: APITypes.TResponse<Types.TAdvanceDetails> = await axiosClient({
    url: `${endpoints.advances.GET_LIST}/${id}`,
    method: 'GET',
  });

  return response.dato || null;
};

export const getDetailsExt = async (id: number): Promise<Types.TAdvanceDetailsExt | null> => {
  const response: APITypes.TResponse<Types.TAdvanceDetailsExt> = await axiosClient({
    url: `${endpoints.advances.GET_DETAILS}/${id}`,
    method: 'GET',
  });

  return response.dato || null;
};

export const getAccountingList = async (
  form: APITypes.TListForm,
): Promise<APITypes.TResponseList<Types.TAdvanceItem>> => {
  const response: APITypes.TResponseList<Types.TAdvanceItem> = await axiosClient({
    url: endpoints.advances.GET_ACCOUNTING_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const denyLegalization = async (id: number, reason: string): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_DENY_LEGALIZATION}/${id}`,
    method: 'PUT',
    data: { id, motivo: reason },
  });

  return response.mensaje;
};

export const approveLegalization = async (id: number, reason: string): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_APPROVE_LEGALIZATION}/${id}`,
    method: 'PUT',
    data: { nota: reason },
  });

  return response.mensaje;
};

export const denyAccounting = async (id: number, reason: string): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_DENY_LEGALIZATION_COUNTER}/${id}`,
    method: 'PUT',
    data: { id, motivo: reason },
  });

  return response.mensaje;
};

export const approveAccounting = async (id: number): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_FINISH_COUNTER}/${id}`,
    method: 'PUT',
  });

  return response.mensaje;
};

export const getAdvancesList = async (
  form: APITypes.TListForm<Types.TGetAdvanceListForm>,
): Promise<APITypes.TResponseList<Types.TAdvanceItem>> => {
  const response: APITypes.TResponseList<Types.TAdvanceItem> = await axiosClient({
    url: endpoints.advances.GET_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const denyAdvance = async (id: number, reason: string): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_DENY}/${id}`,
    method: 'PUT',
    data: { id, motivo: reason },
  });

  return response.mensaje;
};

export const approveAdvance = async (id: number): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_APPROVE}/${id}`,
    method: 'PUT',
  });

  return response.mensaje;
};

export const getFacilitatorList = async (
  form: APITypes.TListForm,
): Promise<APITypes.TResponseList<Types.TAdvanceItem>> => {
  const response: APITypes.TResponseList<Types.TAdvanceItem> = await axiosClient({
    url: endpoints.advances.GET_FACILITATOR_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const setFacilitator = async (id: number): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_SET_FACILITATOR}/${id}`,
    method: 'PUT',
  });

  return response.mensaje;
};

export const manageAdvance = async (id: number): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_MANAGE}/${id}`,
    method: 'PUT',
  });

  return response.mensaje;
};

export const getFacilitatorHistoryList = async (
  form: APITypes.TListForm,
): Promise<APITypes.TResponseList<Types.TAdvanceItem>> => {
  const response: APITypes.TResponseList<Types.TAdvanceItem> = await axiosClient({
    url: endpoints.advances.GET_FACILITATOR_HISTORY_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const reassignFacilitator = async (id: number, idFacilitator: string): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: endpoints.advances.PUT_REASSIGN_FACILITATOR,
    method: 'PUT',
    params: {
      idAnticipo: id,
      idFacilitador: idFacilitator,
    },
  });

  return response.mensaje;
};

export const getAccountingReport = async (type: Types.TAccountingReportsID): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: `${endpoints.advances.GET_ACCOUNTING_REPORT_BY_STATE}/${type}`,
    method: 'GET',
  });

  return response.dato || '#';
};

export const getAdvanceHistory = async (id: number): Promise<Types.TAdvanceHistory[]> => {
  const response: APITypes.TResponseList<Types.TAdvanceHistory> = await axiosClient({
    url: `${endpoints.advances.GET_HISTORY_LIST}/${id}`,
    method: 'GET',
  });

  return response.dato || []; // El back no ejecuta
};

export const addAdvance = async (form: Types.TAddAdvanceForm): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: endpoints.advances.POST_ADD,
    method: 'POST',
    data: form,
  });

  return response.mensaje;
};

export const generateTreasuryReport = async (
  form: Types.TGenerateTreasuryReportForm,
): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: endpoints.advances.GET_TREASURY_REPORT,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || '#';
};

export const getHistoryYears = async (): Promise<number[]> => {
  const response: APITypes.TResponse<number[]> = await axiosClient({
    url: endpoints.advances.GET_HISTORY_YEARS,
    method: 'GET',
  });

  return response.dato || [];
};

export const getTreasuryHistoryYears = async (): Promise<number[]> => {
  const response: APITypes.TResponse<number[]> = await axiosClient({
    url: endpoints.advances.GET_TREASURY_HISTORY_YEARS,
    method: 'GET',
  });

  return response.dato || [];
};

export const getTreasuryHistoryList = async (
  form: APITypes.TListForm<Types.TTreasuryHistoryListForm>,
): Promise<APITypes.TResponseList<Types.TTreasuryHistoryItem>> => {
  const response: APITypes.TResponseList<Types.TTreasuryHistoryItem> = await axiosClient({
    url: endpoints.advances.GET_TREASURY_HISTORY_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const generateTreasuryHistoryReport = async (
  form: Types.TTreasuryHistoryReportForm,
): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: endpoints.advances.GET_TREASURY_HISTORY_REPORT,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || '#';
};

export const getCompensationList = async (
  form: APITypes.TListForm<Types.TCompensationListForm>,
): Promise<APITypes.TResponseList<Types.TCompensationListItem>> => {
  const response: APITypes.TResponseList<Types.TCompensationListItem> = await axiosClient({
    url: endpoints.advances.GET_COMPENSATION_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const generateCompensationReport = async (
  form: Types.TGenerateCompensationReportForm,
): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: endpoints.advances.GET_COMPENSATION_REPORT,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || '#';
};

export const getLegalization = async (id: number): Promise<Types.TLegalization | null> => {
  const response: APITypes.TResponse<Types.TLegalization> = await axiosClient({
    url: `${endpoints.advances.GET_LEGALIZATION}/${id}`,
    method: 'GET',
  });

  return response.dato || null;
};

export const getHistoryYearsApprover = async (): Promise<number[]> => {
  const response: APITypes.TResponse<number[]> = await axiosClient({
    url: endpoints.advances.GET_HISTORY_YEARS_APPROVER,
    method: 'GET',
  });
  return response.dato || [];
};

export const getAdminReportYears = async (): Promise<number[]> => {
  const response: APITypes.TResponse<number[]> = await axiosClient({
    url: endpoints.advances.GET_ADMIN_REPORT_YEARS,
    method: 'GET',
  });

  return response.dato || [];
};

export const getAdminReportList = async (
  form: APITypes.TListForm<Types.TAdminReportFilter>,
): Promise<APITypes.TResponseList<Types.TAdminReportListItem>> => {
  const response: APITypes.TResponseList<Types.TAdminReportListItem> = await axiosClient({
    url: endpoints.advances.GET_ADMIN_REPORT_LIST,
    method: 'GET',
    params: formatParams(form),
  });

  return response;
};

export const generateAdminReport = async (
  form: APITypes.TReportForm<Types.TAdminReportFilter>,
): Promise<string> => {
  const response: APITypes.TResponse<string> = await axiosClient({
    url: endpoints.advances.GET_ADMIN_REPORT_FILE,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || '#';
};

export const addFacilitatorSupports = async (
  id: number,
  data: Types.TAddFacilitatorSupports,
): Promise<APITypes.TResponse<Types.TFacilitatorSupports>> => {
  const response: APITypes.TResponse<Types.TFacilitatorSupports> = await axiosClient({
    url: `${endpoints.advances.PUT_FACILITATOR_SUPPORTS}/${id}`,
    method: 'PUT',
    data,
  });

  return response;
};

export const getFacilitatorSupports = async (
  id: number | undefined,
): Promise<Types.TGetFacilitatorSupportsRes | undefined> => {
  const response: APITypes.TResponse<Types.TGetFacilitatorSupportsRes> = await axiosClient({
    url: `${endpoints.advances.GET_FACILITATOR_SUPPORTS}/${id}`,
    method: 'GET',
  });

  return response.dato;
};

export const denyAdvanceTreasury = async (id: number, reason: string): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_DENY_TREASURY}/${id}`,
    method: 'PUT',
    data: { motivo: reason },
  });

  return response.mensaje;
};

export const addIncidence = async (
  form: Types.TIncidenceFacilitator,
  id: number,
): Promise<string> => {
  const response: APITypes.TResponse = await axiosClient({
    url: `${endpoints.advances.PUT_ADD_INCIDENCE}/${id}`,
    method: 'PUT',
    data: form,
  });

  return response.mensaje;
};

export const getIncidence = async (
  id: number,
): Promise<Types.TIncidenceFacilitator | undefined> => {
  const response: APITypes.TResponse<Types.TIncidenceFacilitator> = await axiosClient({
    url: `${endpoints.advances.GET_INCIDENCE}/${id}`,
    method: 'GET',
  });

  return response.dato;
};

export const getEnablePoll = async (id: number): Promise<Types.TEnablePoll | null> => {
  const response: APITypes.TResponse<Types.TEnablePoll> = await axiosClient({
    url: `${endpoints.advances.GET_PARAMETER_ID}/${id}`,
    method: 'GET',
  });

  return response.dato || null;
};

export const toggleEnablePoll = async (id: number): Promise<Types.TEnablePoll | null> => {
  const response: APITypes.TResponse<Types.TEnablePoll> = await axiosClient({
    url: `${endpoints.advances.GET_PARAMETER_ID}/${id}`,
    method: 'PUT',
  });

  return response.dato || null;
};

export const getAdvanceItem = async (
  form: APITypes.TListForm<Types.TGetAdvanceItemForm>,
): Promise<Types.TAdvanceItem[]> => {
  const response: APITypes.TResponse<Types.TAdvanceItem[]> = await axiosClient({
    url: endpoints.advances.GET_ADVANCE_ITEM,
    method: 'GET',
    params: formatParams(form),
  });

  return response.dato || [];
};

export const reassignApproverAdvances = async (
  idSolicitud: number,
  idAprobador: string,
): Promise<string> => {
  const response: APITypes.TResponseChangeApprover = await axiosClient({
    url: `${endpoints.advances.PUT_CHANGE_APPROVER}/${idSolicitud}/aprobador/${idAprobador}`,
    method: 'PUT',
  });

  return response.dato || '';
};
