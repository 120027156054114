/**
 * Solicitudes Pragma SA
 */

// BASE URL
const BASE_URL_VACACIONES = `${process.env.REACT_APP_DOMAIN}/solicitudes`;
const BASE_URL_ANTICIPOS = `${process.env.REACT_APP_DOMAIN}/anticipo`;
const BASE_URL_VACACION = process.env.REACT_APP_DOMAIN;
const BASE_URL_CATALOGOS = process.env.REACT_APP_CATALOGS;
const BASE_URL_PERFIL = process.env.REACT_APP_PROFILE;
const BASE_URL_SEGURIDAD = process.env.REACT_APP_SECURITY;
const BASE_URL_FILES = process.env.REACT_APP_FILE;
const BASE_URL_MULTIMEDIA = process.env.REACT_APP_MULTIMEDIA;

export default {
  GET_TOKEN: `${process.env.REACT_APP_DOMAIN}/token`,
  GET_MULTIMEDIA: BASE_URL_MULTIMEDIA,
  catalogs: {
    GET_COUNTRIES: `${BASE_URL_CATALOGOS}/ubicaciones-geograficas`,
    GET_BUSINESS: `${BASE_URL_CATALOGOS}/vicepresidencia/listar/activa?activa=true`,
    GET_WORKING_DAYS: `${BASE_URL_VACACION}/festivos/cantidad-dias-entre-fechas`,
    GET_NON_WORKING_DAYS: `${BASE_URL_CATALOGOS}/pais-dias`,
    GET_DAYS_VACATION: `${BASE_URL_CATALOGOS}/fecha-final`, // NOT IN USE
    GET_CURRENCY: `${BASE_URL_CATALOGOS}/Moneda`,
    GET_CLIENTS_BY_BUSINESS: `${BASE_URL_CATALOGOS}/marca/listarByVicepresidencia`,
    GET_CLIENTS: `${BASE_URL_CATALOGOS}/marca/listar`,
    GET_TYPE_DOCUMENT: `${BASE_URL_CATALOGOS}/tipo-documento`,
    GET_CITIES: `${BASE_URL_ANTICIPOS}/ciudad`,
  },
  profile: {
    GET_USER: `${BASE_URL_PERFIL}/usuarios/token`,
    GET_EXT_USER: `${BASE_URL_PERFIL}/usuarios/token/identificacion`,
    GET_USER_BY_DOCUMENT: `${BASE_URL_VACACIONES}/usuario`,
  },
  security: {
    GET_MENU: `${BASE_URL_SEGURIDAD}/appusurolper`,
    GET_APPROVERS: `${BASE_URL_SEGURIDAD}/appusurolper`,
    GET_APPROVERS_BY_BUSINESS: `${BASE_URL_SEGURIDAD}/usuarios/vicepresidencia`,
  },
  vacations: {
    POST_ADD: `${BASE_URL_VACACIONES}/`,
    GET_LIST: `${BASE_URL_VACACIONES}`,
    GET_EXT_LIST: `${BASE_URL_VACACIONES}/extendido`,
    PUT_UPDATE_STATE: `${BASE_URL_VACACIONES}`,
    GET_DAYS_AVAILABLE: `${BASE_URL_VACACIONES}/usuario/dias-vacaciones`,
    GET_ENABLE_BUTTON_LIQUIDATE: `${BASE_URL_VACACIONES}/liquidar/habilitado`,
    GET_YEARS: `${BASE_URL_VACACIONES}/anios`,
    PUT_UPDATE_LIQUIDATE: `${BASE_URL_VACACIONES}/liquidar`,
    GET_GENERATE_REPORT: `${BASE_URL_VACACIONES}/generar-reporte`,
    GET_YEARS_AVAILABLE_HISTORY: `${BASE_URL_VACACIONES}/anios-historial`,
    POST_ADD_PAID_VACATIONS: `${BASE_URL_VACACIONES}/vacacionesPagas`,
    GET_NOTIFICATIONS: `${BASE_URL_VACACIONES}/procesosPendientes`,
    GET_OVERVIEW_REPORT: `${BASE_URL_VACACIONES}/archivoVistaGeneralVacaciones`,
    GET_APPROVER_HISTORY_YEARS: `${BASE_URL_VACACIONES}/anios-historial-aprobador`,
    GET_PARAMETER_ID: `${BASE_URL_VACACIONES}/parametros`,
    GET_INFORMATION_PRAGMATIC: `${BASE_URL_VACACIONES}/informacion-pragmatico`,
    GET_INFORMATION_PRAGMATIC_REPORT: `${BASE_URL_VACACIONES}/informacion-reporte`,
    PUT_CHANGE_APPROVER: `${BASE_URL_VACACIONES}/actualizarAprobador`,
    POST_SATISFACTION_SURVEY: `${BASE_URL_VACACIONES}/encuesta-satisfaccion`,
  },
  advances: {
    POST_ADD: `${BASE_URL_ANTICIPOS}`,
    GET_LIST: `${BASE_URL_ANTICIPOS}`,
    PUT_DENY: `${BASE_URL_ANTICIPOS}/rechazar`,
    PUT_APPROVE: `${BASE_URL_ANTICIPOS}/aprobar`,
    GET_FACILITATOR_LIST: `${BASE_URL_ANTICIPOS}/facilitador`,
    PUT_SET_FACILITATOR: `${BASE_URL_ANTICIPOS}/gestionar`,
    GET_TREASURY_LIST: `${BASE_URL_ANTICIPOS}/tesorero`,
    PUT_SET_PAID: `${BASE_URL_ANTICIPOS}/pagar`,
    PUT_LEGALIZE: `${BASE_URL_ANTICIPOS}/legalizar`,
    GET_ACCOUNTING_LIST: `${BASE_URL_ANTICIPOS}/contabilidad`,
    PUT_DENY_LEGALIZATION: `${BASE_URL_ANTICIPOS}/rechazar/legalizacion`,
    PUT_APPROVE_LEGALIZATION: `${BASE_URL_ANTICIPOS}/aprobar/legalizar`,
    PUT_DENY_LEGALIZATION_COUNTER: `${BASE_URL_ANTICIPOS}/rechazar/legalizacion/contabilidad`,
    PUT_FINISH_COUNTER: `${BASE_URL_ANTICIPOS}/finalizar`,
    PUT_MANAGE: `${BASE_URL_ANTICIPOS}/marcar/gestionar`,
    GET_FACILITATORS: `${BASE_URL_ANTICIPOS}/facilitadores`,
    PUT_REASSIGN_FACILITATOR: `${BASE_URL_ANTICIPOS}/reasignar/facilitador`,
    GET_FACILITATOR_HISTORY_LIST: `${BASE_URL_ANTICIPOS}/historial`,
    GET_DETAILS: `${BASE_URL_ANTICIPOS}/revisar`,
    GET_ACCOUNTING_REPORT_BY_STATE: `${BASE_URL_ANTICIPOS}/reportes/por-estado`,
    GET_HISTORY_LIST: `${BASE_URL_ANTICIPOS}/trazabilidad`,
    GET_TREASURY_REPORT: `${BASE_URL_ANTICIPOS}/reporte/tesoreria`,
    GET_NOTIFICATIONS: `${BASE_URL_ANTICIPOS}/procesosPendientes`,
    GET_HISTORY_YEARS: `${BASE_URL_ANTICIPOS}/anios-mis-anticipos`,
    GET_TREASURY_HISTORY_YEARS: `${BASE_URL_ANTICIPOS}/anios-anticipos-pagados`,
    GET_TREASURY_HISTORY_LIST: `${BASE_URL_ANTICIPOS}/historial-tesorero`,
    GET_TREASURY_HISTORY_REPORT: `${BASE_URL_ANTICIPOS}/reportes/historial-tesorero`,
    GET_COMPENSATION_LIST: `${BASE_URL_ANTICIPOS}/compensacion`,
    GET_COMPENSATION_REPORT: `${BASE_URL_ANTICIPOS}/reportes/compensacion`,
    GET_LEGALIZATION: `${BASE_URL_ANTICIPOS}/legalizar-detalles`,
    GET_HISTORY_YEARS_APPROVER: `${BASE_URL_ANTICIPOS}/anios-historial-aprobador`,
    GET_ADMIN_REPORT_YEARS: `${BASE_URL_ANTICIPOS}/anios-anticipos-general`,
    GET_ADMIN_REPORT_LIST: `${BASE_URL_ANTICIPOS}/anticipos-administrador`,
    GET_ADMIN_REPORT_FILE: `${BASE_URL_ANTICIPOS}/reportes/anticipos-administrador`,
    PUT_FACILITATOR_SUPPORTS: `${BASE_URL_ANTICIPOS}/soporte-facilitador`,
    GET_FACILITATOR_SUPPORTS: `${BASE_URL_ANTICIPOS}/obtener-soportes`,
    PUT_DENY_TREASURY: `${BASE_URL_ANTICIPOS}/rechazar-tesoreria`,
    PUT_ADD_INCIDENCE: `${BASE_URL_ANTICIPOS}/agregarincidencia`,
    GET_INCIDENCE: `${BASE_URL_ANTICIPOS}/obtenerincidencia`,
    GET_PARAMETER_ID: `${BASE_URL_ANTICIPOS}/parametroEncuesta`,
    GET_ADVANCE_ITEM: `${BASE_URL_ANTICIPOS}/cards`,
    PUT_CHANGE_APPROVER: `${BASE_URL_ANTICIPOS}`,
    GET_PREFIRMED_URL: `${BASE_URL_FILES}/file/presigned-url`,
  },
};
