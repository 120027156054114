/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import { MdOutlineFileDownload, MdRemoveRedEye } from 'react-icons/md';

import { CancelTreasuryIcon } from '@/assets/icons/icons';
import Table from '@/components/table';
import Button from '@/components/ui/button';
import UploadButton from '@/components/uploadButton';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { TPhysicalInvoice } from '@/services/advances/types';
import downloadFile from '@/utils/downloadFile';

import i18n from './i18n.json';
import styles from './styles.module.scss';
import cleanFileName from '@/utils/cleanFIleName';

type TProps = {
  data: TPhysicalInvoice[];
  setData?: (value: TPhysicalInvoice[]) => void;
  readOnly?: boolean;
  hideTitle?: boolean;
  hideReminder?: boolean;
  title?: string;
};

const Supports = ({ data, setData, readOnly, hideTitle, hideReminder, title }: TProps) => {
  const t = useLocalTranslation(i18n as Resource);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const convertBase64ToBlob = (base64: string) => {
    const parts = base64.split(';base64,');
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: parts[0].split(':')[1] });
  };

  const viewFile = (fileURL: string) => {
    const splitFile = fileURL.split('.');
    if (splitFile[splitFile.length - 1] === 'pdf')
      window.open(`https://docs.google.com/viewerng/viewer?url=${fileURL}`);
    else {
      window.open('', '_blank')?.document.write(`
        <html>
          <head>
          </head>
          <body>
            <img src="${fileURL}" alt="${fileURL}" maxheight="100%" maxwidth="100%">
          </body>
        </html>
      `);
    }
  };

  const handleChangeInput = (
    e: any,
    index: number,
    name: 'nombreEstablecimiento' | 'descripcionFactura',
  ) => {
    if (name === 'nombreEstablecimiento') {
      data.map((item, i) => {
        if (i == index) {
          item.nombreEstablecimiento = e.target.value;
        }
      });
    } else {
      data.map((item, i) => {
        if (i == index) {
          item.descripcionFactura = e.target.value;
        }
      });
    }
    setData!(data);
  };

  return (
    <div className={styles['supports']}>
      {!hideTitle && <h3>{t('title')}</h3>}
      {title && <h3>{title}</h3>}
      {!readOnly && !hideReminder && (
        <p className={styles['reminder']}>
          <strong>{t('reminder.title')} </strong>
          {t('reminder.description')}
          <strong> {`${t('reminder.important')}. 😊`}</strong>
        </p>
      )}
      <Table
        name="ant-supports_table"
        header={[
          { name: 'nombreEstablecimiento', label: t('table.nombreEstablecimiento') },
          { name: 'filename', label: t('table.files') },
          { name: 'descripcionFactura', label: t('table.descripcionFactura') },
          { name: 'actions', label: t('table.actions') },
        ]}
        data={data.map((item, _key) => ({
          _key,
          nombreEstablecimiento: (
            <input
              className={styles['descripcion-factura']}
              onChange={(e) => handleChangeInput(e, _key, 'nombreEstablecimiento')}
              placeholder={item.nombreEstablecimiento}
            />
          ),
          filename:
            item?.nombreSoporte !== null
              ? item.nombreSoporte
              : `${t('table.fileName')} ${_key + 1}`,
          descripcionFactura: (
            <textarea
              className={styles['descripcion-factura']}
              onChange={(e) => handleChangeInput(e, _key, 'descripcionFactura')}
              placeholder={item.descripcionFactura}
            />
          ),
          actions: (
            <div className={styles['actions']}>
              <Button
                name="ant-legalization-supports-view"
                theme="secondary"
                onClick={() => {
                  readOnly
                    ? viewFile(item.urlSoporte as string)
                    : window.open(URL.createObjectURL(convertBase64ToBlob(item.archivoSoporte as string)));
                }}
              >
                <MdRemoveRedEye />
              </Button>
              {readOnly ? (
                <Button
                  name="ant-legalization-review_button-download-support"
                  className={styles.download}
                  theme="secondary"
                  onClick={() => downloadFile(item.urlSoporte as string)}
                >
                  <MdOutlineFileDownload />
                </Button>
              ) : (
                <Button
                  name="ant-legalization-supports_button-delete"
                  theme="error"
                  onClick={() => {
                    const tmpList = [...data];
                    tmpList.splice(_key, 1);
                    const tmpFileNames = [...fileNames];
                    tmpFileNames.splice(_key, 1);
                    if (setData) setData(tmpList);
                    setFileNames(tmpFileNames);
                  }}
                >
                  <CancelTreasuryIcon width={12} height={12} />
                </Button>
              )}
            </div>
          ),
        }))}
        emptyLabel={`${t('noData')} 🚀`}
      />
      {/* {assignValues('');} */}
      {!readOnly && (
        <UploadButton
          className={styles['upload']}
          maxSize={1000000}
          fileTypes={['png', 'jpge', 'jpg', 'jpeg', 'pdf']}
          onChange={(file, base64) => {
            const sanitizedFileName = cleanFileName(file.name);

            if (setData) {
              setData([...data, { nombreSoporte: sanitizedFileName, archivoSoporte: base64 as string, archivo: file }]);
            }
            setFileNames([...fileNames, sanitizedFileName]);
          }}
        />
      )}
    </div>
  );
};

export default Supports;
