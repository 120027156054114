import axios from "axios";

import axiosClient from "@/services/axiosClient";
import endpoints from "@/services/endpoints";
import type * as APITypes from '@/services/types';


export const uploadFilesToS3 = async ({ folder,files }: { folder: string;files: File[]; }): Promise<string[] | undefined> => {
      const fileNames = files.map((file) => {
        const extIndex = file.name.lastIndexOf('.');
        const nameWithoutExt = extIndex !== -1 ? file.name.slice(0, extIndex) : file.name;
        const ext = extIndex !== -1 ? file.name.slice(extIndex) : '';
        
        return `${nameWithoutExt}-${Date.now()}${ext}`;
      }).join(',');
  
      const response: APITypes.PresignedUrl = await axiosClient({
        url: `${endpoints.advances.GET_PREFIRMED_URL}?operacion=upload&carpeta=${folder}&nombreArchivos=${fileNames}`,
        method: 'GET',
      });
  
      const presignedUrls = response?.urls || [];
  
      if (presignedUrls.length !== files.length) {
        throw new Error('La cantidad de URLs prefirmadas no coincide con la cantidad de archivos');
      }

      await Promise.all(
        files.map((file, index) => {
          const { preSignedUrl } = presignedUrls[index];
  
          return axios.put(preSignedUrl, file, {
            headers: {
                'Content-Type': file.type, 
            },
            transformRequest: [(data, headers) => {
              delete headers?.Authorization;
              return data;
            }],
          });
        })
      );
            
      // Retornar las URLs formateadas después de la subida
      return presignedUrls.map((url) => url.formattedUrl);
  };
  